import React from "react"
import Layout from "../components/layout"
import { graphql, Link, navigate, useEffect } from "gatsby"
import SEO from "../components/seo"

const PostTemplate = (data) => {

  //const response_obj = JSON.parse(data);
  const title = data.pathContext.title;
  const html = data.pathContext.body_html;

  //alert(title);

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  //useEffect(() => {
  //addWebEvent("visit", title.toString())
  //});


  return (
    <>
    <SEO title={title} />
    <Layout>
    
      <div className="h-5"></div>
      <div className="relative mt-20">
        <div className="flex justify-center items-center py-6">
          <h1 className="tracking-wide font-light px-6 md:px-20 lg:px-40 xl:px-84 text-center">
            {title}
          </h1>
        </div>
      </div>
      <div className="w-full border-t blog-main">
        <div
          className="px-6 md:px-20 lg:px-80 xl:px-90 py-10 relative bg-white blog-body font-light tracking-wide"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
      <div className="flex justify-center pb-20">
        <Link
          to="/blog"
          className="bg-brown-900 border border-brown-900 hover:bg-transparent text-white hover:text-brown-900 w-40 mt-12 px-8 py-4 text-center font-bradford1 tracking-wide"
        >
          Back to Blog
        </Link>
      </div>
    </Layout>
    </>
  )
}

export default PostTemplate